<template>
  <v-container fluid class="full-fill pa-0">
    <v-row class="full-fill">
      <!-- Search -->
      <v-col cols="2.5" class="d-none d-sm-flex ma-0 pa-5">
      </v-col>
      <!-- Content -->
      <v-col class="col-sm-7 ma-0 mt-5 mt-sm-0 pa-5 pl-5 pr-5 pl-sm-0 pr-sm-0">
        <v-row class="full-fill">
          <v-col class="d-none d-sm-flex ma-0 pa-5">
          </v-col>
          <v-col align="center" class="col-sm-6 ma-0 pa-0">
            <v-card class="pa-5 w-100" fluid>
              <div v-if="isAuth">
                <h2>You are logged in.</h2>
                <v-btn
                  class="blue darken-3 mr-3 mt-3 white--text"
                  to="/account"
                >
                  My Account
                </v-btn>
                <v-btn
                  class="blue darken-3 mt-3 white--text"
                  @click="logOut"
                >
                  Log Out
                </v-btn>
              </div>
              <div v-else>
                <h1>Log In</h1>
                <v-form
                  @submit.prevent="submit"
                  ref="form"
                  v-model="valid"
                >
                  <v-text-field
                    v-model="email"
                    :rules="emailRules"
                    ref="emailField"
                    label="Email Address"
                    required
                    @keydown.enter="submit"
                  ></v-text-field>
                  <v-text-field
                    v-model="password"
                    :rules="passwordRules"
                    :type="'password'"
                    label="Password"
                    required
                    @keydown.enter="submit"
                  ></v-text-field>
                  <v-btn
                    type="submit"
                    class="blue darken-3 mt-3 white--text"
                    :loading="loading"
                  >
                    Log In
                  </v-btn>
                  <div class="error--text mt-3">{{ error }}</div>
                  <router-link :to="{ path: 'auth-management', query: { mode: 'resetPassword' } }">Forgot Password?</router-link>
                </v-form>
              </div>
            </v-card>
          </v-col>
          <v-col class="d-none d-sm-flex ma-0 pa-5">
          </v-col>
        </v-row>
      </v-col>
      <!-- Ads -->
      <v-col cols="2.5" class="d-none d-sm-flex ma-0 pa-5">
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'LogIn',

    data () {
      return {
        valid: false,
        email: '',
        emailRules: [
          v => !!v || 'E-mail is required.',
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid.'
        ],
        error: '',
        loading: false,
        password: '',
        passwordRules: [
          v => !!v || 'Password is required.'
        ]
      }
    },

    computed: {
      ...mapGetters([
        'isAuth'
      ])
    },

    mounted () {
      if (!this.isAuth) this.$refs.emailField.focus()
    },

    methods: {
      logOut () {
        this.$store.dispatch('logOut')
      },

      submit () {
        this.error = ''
        this.$refs.form.validate()
        if (this.valid && !this.loading) {
          this.loading = true
          const { email, password } = this
          this.$store.dispatch('logIn', { email, password })
          .then(() => {
            this.loading = false
          })
          .catch((err) => {
            this.loading = false
            this.error = err
          })
        }
      }
    }
  }
</script>

<style scoped>
  .full-fill {
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important;
  }
</style>